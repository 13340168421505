import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Header } from 'semantic-ui-react';
import EntityLink from '../../../components/EntityLink';
import SpeakerAvatars from '../../../components/speakers/SpeakerAvatars';
import ReplayModal from '../../../components/video/ReplayModal';
import WorkshopDate from '../../../components/workshops/WorkshopDate';
import { workshopProptypes } from '../../../propTypes';
import { getString } from '../../../utils';
import Images from '../../../utils/Images';
import WorkshopRegistrationButton from '../../../workshops/components/WorkshopRegistrationButton';
import { getClassName, getWorkshopString } from '../../../workshops/utils';
import './WorkshopImageItem.scss';
import './WorkshopLightItem.scss';

const translationPrefix = 'workshops.workshop';

const SeeMoreButton = ({ workshop }) => {
  const hasLive =
    workshop.liveStreams && workshop.liveStreams.length > 0 && !!workshop.liveStreams[0].uri;

  if (hasLive) {
    return (
      <Button
        className="live-button"
        as={EntityLink}
        entity={workshop}
        primary
        icon="play"
        content={getString(`${translationPrefix}.btn.live`)}
      />
    );
  }
  return (
    <Button
      className="see-more-button"
      as={EntityLink}
      entity={workshop}
      content={getString(`${translationPrefix}.see-more`)}
    />
  );
};

export const WorkshopActions = ({ workshop, showDetails, showReplay }) => {
  const { _id: workshopId, pageHidden, pageLiveOnlyForRegistered } = workshop;
  const isUserRegistered = useSelector(
    (state) => state.registrations.registrationsById[workshopId],
  );
  const isButtonDisplayed =
    showDetails && !pageHidden && (isUserRegistered || !pageLiveOnlyForRegistered);
  const hasReplay =
    workshop.replays && workshop.replays.length > 0 && workshop.replays[0].value?.uri;
  return (
    <div className="actions">
      <WorkshopRegistrationButton workshop={workshop} />
      {showReplay && hasReplay && (
        <ReplayModal
          item={workshop}
          replay={workshop.replays[0]}
          trigger={
            <Button className="replay-button">{getString(`${translationPrefix}.replay`)}</Button>
          }
        />
      )}
      {isButtonDisplayed && <SeeMoreButton workshop={workshop} />}
    </div>
  );
};

SeeMoreButton.propTypes = {
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

WorkshopActions.defaultProps = {
  showDetails: true,
  showReplay: true,
};

WorkshopActions.propTypes = {
  workshop: PropTypes.shape(workshopProptypes).isRequired,
  showDetails: PropTypes.bool,
  showReplay: PropTypes.bool,
};

const WorkshopImageItem = ({ workshop, config }) => {
  const {
    showActions = true,
    showSpeakers = true,
    showDate = true,
    showEndTime = false,
    showDetails = true,
    showReplay = true,
    speakerConfig = {},
  } = config;
  const { shortDescription, image, speakers, title, startDate, endDate, category } = workshop;
  return (
    <Card className={cx('Workshop', getClassName(workshop))}>
      <Card.Content className="Workshop__Container">
        {image && image.uri && (
          <div
            className="Workshop__Container--Image"
            style={{
              backgroundImage: `url(${Images.cdn(image)})`,
            }}
          />
        )}
        <div className="Workshop__Container--Content">
          <div style={{ flex: 1 }}>
            {title && (
              <Header className="title" as="h3">
                {title}
              </Header>
            )}
            {category && <p className="category">{getWorkshopString(workshop, 'category')}</p>}
            {showDate && (
              <Card.Description className="date">
                <WorkshopDate startDate={startDate} endDate={endDate} showEndTime={showEndTime} />
              </Card.Description>
            )}
            {shortDescription && (
              <Card.Description className="description">{shortDescription}</Card.Description>
            )}
            {showSpeakers && (
              <div className="speakers">
                <SpeakerAvatars size="mini" speakerIds={speakers} {...speakerConfig} />
              </div>
            )}
          </div>
          {showActions && (
            <WorkshopActions
              workshop={workshop}
              showDetails={showDetails}
              showReplay={showReplay}
            />
          )}
        </div>
      </Card.Content>
    </Card>
  );
};

WorkshopImageItem.defaultProps = {
  config: {},
};

WorkshopImageItem.propTypes = {
  config: PropTypes.object,
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export default WorkshopImageItem;
