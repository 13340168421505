/* eslint-disable import/no-cycle */
import throttle from 'lodash/throttle';
import { parse } from 'querystring';
import React, { Component } from 'react';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { fetchAgenda } from '../agenda/store/agenda.actions';
import authService from '../core/services/auth.service';
import platformService from '../core/services/platform.service';
import { uuid } from '../core/trackers/utils';
import { dispatchData, fetchRegistrations, fetchUser } from '../store/actions';
import { authLogin, authLogout } from '../store/auth/auth.actions';
import { getFirebase } from '../store/effects/firebase.effects';
import mainSaga from '../store/mainSaga';
import reducers from '../store/reducers';
import { setAppointments } from '../store/reducers/appointments';
import { setStoreUser } from '../store/reducers/user';
import MobileStorage from './storage/MobileStorage';
import WebStorage from './storage/WebStorage';

export function getUrlParameters(url) {
  // eslint-disable-next-line no-param-reassign, no-undef
  if (!url) url = window.location.href;
  const index = url.indexOf('?');
  if (index === -1) return {};
  return parse(url.slice(index + 1));
}

export function getParameterByName(name, url) {
  // eslint-disable-next-line no-param-reassign, no-undef
  if (!url) url = window.location.href;
  // eslint-disable-next-line no-useless-escape, no-param-reassign
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

class Store {
  constructor() {
    const { eventId } = window.__DATA__;
    const Storage = window.ReactNativeWebView ? MobileStorage : WebStorage;
    this.storage = new Storage(eventId);

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const sagaMiddleware = createSagaMiddleware();
    this.reduxStore = createStore(
      reducers,
      undefined,
      composeEnhancers(applyMiddleware(thunk, sagaMiddleware)),
    );

    this.listeners = [];
    this.eventId = eventId;
    this.isInit = false;

    this.initFromStorage();

    sagaMiddleware.setContext({
      appCraftStore: this,
      setGlobalContext: (context) => sagaMiddleware.setContext(context),
    });
    sagaMiddleware.run(mainSaga);
    this.sagasRunner = sagaMiddleware;
    // this.init();

    if (this.isMobileApp()) {
      window.store = this;
    }
  }

  async checkImpersonator(impersonationToken) {
    const result = await authService.impersonate(impersonationToken);
    if (result.success) {
      this.onImpersonate(result);
    }
  }

  onImpersonate(res) {
    console.log('onImpersonate', res);
    const { user, token, sessionId, impersonator } = res;
    this.setUser(user, token, sessionId, impersonator);
    this.renewFirebaseToken();
    this.refresh();

    // Remove token from url
    if (window.history && window.history.replaceState) {
      window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  isMobileApp() {
    return !!window.ReactNativeWebView;
  }

  async checkLogin() {
    if (this.storage.waitForReady) {
      await this.storage.waitForReady();
    }
    const eventData = this.storage.getItem();
    if (eventData) {
      const { user, token, sessionId } = eventData;
      if (user && token) {
        this.setUser(user, token, sessionId);
        this.renewFirebaseToken();
        this.refresh();
      }
      return user;
    }
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getAnonymousId() {
    let anonymousId = localStorage.getItem('anonymousId');
    if (!anonymousId) {
      anonymousId = uuid();
      localStorage.setItem('anonymousId', anonymousId);
    }
    return anonymousId;
  }

  async checkConnection() {
    if (window.debugImpersonate && window.debugImpersonate.success) {
      this.onImpersonate(window.debugImpersonate);
      return;
    }
    const impersonationToken = getParameterByName('impersonationToken');
    if (impersonationToken) {
      await this.checkImpersonator(impersonationToken);
    } else {
      const user = await this.checkLogin();
      if (!user && this.allowAnonymous()) {
        // Auto-connect as guest
        const { user: anonymousUser, token } = await authService.signInAnonymously(
          this.getAnonymousId(),
        );
        if (anonymousUser && token) {
          this.setUser(anonymousUser, token, '');
          this.renewFirebaseToken();
          this.refresh();
        }
      }
    }
  }

  init() {
    setTimeout(this.refresh, 10);
  }

  setUser(user, token, sessionId, impersonator) {
    this.user = user;
    if (user) {
      this.reduxStore.dispatch(setStoreUser(user));
      this.userId = user._id;
      this.token = token;
      this.sessionId = sessionId;
      this.impersonator = impersonator;
    } else {
      this.eventId = null;
      this.userId = null;
      this.sessionId = null;
      this.impersonator = null;
    }
  }

  async disconnect(reason) {
    this.storage.removeItem(reason);
    this.token = null;
    this.reduxStore.dispatch(authLogout());
    this.user = null;
  }

  // eslint-disable-next-line class-methods-use-this
  hasSavedUser() {
    return !!this.storage.getItem();
  }

  async onLogin(res, { stayConnected }) {
    if (stayConnected) {
      this.storage.setItem({ user: res.user, token: res.token, sessionId: res.sessionId });
    }
    this.setUser(res.user, res.token, res.sessionId);
    this.reduxStore.dispatch(authLogin(res));
    this.refresh();
  }

  async tryLogin(request, stayConnected) {
    try {
      // const extraFields = ['thumbnail', 'networking'];
      const res = await request;
      if (res.success) {
        this.onLogin(res, { stayConnected });
      }
      return res;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.code, ' : ', error.message);
      if (error.message === 'Failed to fetch') throw error; // Propagate
      return null;
    }
  }

  async autoLogin(token, stayConnected) {
    return this.tryLogin(authService.autoLogin(token, 'participants', {}), stayConnected);
  }

  async login(login, password, stayConnected, options) {
    let collection = 'participants';

    // Specific for apirubi  // TODO : set config to delete this code
    if (this.eventId === 'nopMU0TusbbGPM') {
      collection = '';
    }
    return this.tryLogin(authService.login(login, password, collection, options), stayConnected);
  }

  async resetPassword(resetToken, password, autoLogin, stayConnected) {
    let collection = 'participants';

    // Specific for apirubi  // TODO : set config to delete this code
    if (this.eventId === 'nopMU0TusbbGPM') {
      collection = '';
    }
    return this.tryLogin(
      authService.resetPassword(resetToken, password, collection, {
        autoLogin,
      }),
      stayConnected,
    );
  }

  async renewFirebaseToken() {
    console.log('renewFirebaseToken');
    const res = await authService.renewFirebaseToken(this.token);

    this.reduxStore.dispatch(
      authLogin({
        user: this.user,
        token: this.token,
        ...res,
      }),
    );
  }

  async getFirebase() {
    return this.sagasRunner
      .run(function* _getFirebase() {
        return yield getFirebase();
      })
      .toPromise();
  }

  isLoggedIn() {
    return !!this.user && this.user.collection !== 'anonymous';
  }

  isAnonymous() {
    return this.allowAnonymous() && this.user.collection === 'anonymous';
  }

  // eslint-disable-next-line class-methods-use-this
  allowAnonymous() {
    return window.__DATA__.auth?.allowAnonymous;
  }

  mustRedirectToLogin() {
    return !this.allowAnonymous() && !this.isLoggedIn();
  }

  async updateUser(userPatch) {
    let res;
    if (this.isAnonymous()) {
      this.user = { ...this.user, ...userPatch };
      res = { success: true };
    } else {
      res = await platformService.patchUser(userPatch);
    }
    this.reduxStore.dispatch(fetchUser());

    const user = this.storage.getItem();

    if (user) {
      this.storage.setItem({
        user: { ...user.user, ...userPatch },
        token: user.token,
        sessionId: user.sessionId,
      });
    }

    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async updatePassword(newPassword, previousPassword) {
    const res = await platformService.updatePassword(newPassword, previousPassword);
    return res;
  }

  dispatch() {
    const state = this.getState();
    // eslint-disable-next-line no-restricted-syntax
    for (const listener of this.listeners) {
      listener(state);
    }
  }

  getState() {
    return {
      ...this.data,
    };
  }

  refresh = throttle(
    async () => {
      this.isInit = true;
      this.dispatch();
      if (!this.token || this.isPreview) return;

      const [data, appointments] = await Promise.all([
        platformService.fetchData(),
        platformService.fetchAppointments(),
      ]);

      // might have been disconnected in-between
      if (!this.token || data.error || data.workshops?.error) return;

      // // eslint-disable-next-line consistent-return
      // if (data.error) return this.logout();

      // const { workshops } = data;
      // // eslint-disable-next-line consistent-return
      // if (workshops.error) return this.logout();

      this.reduxStore.dispatch(dispatchData(data));
      this.reduxStore.dispatch(fetchRegistrations());
      this.reduxStore.dispatch(setAppointments(appointments));
      this.reduxStore.dispatch(fetchAgenda());
      this.reduxStore.dispatch(fetchUser());
    },
    50,
    { leading: true, trailing: false },
  );

  subscribe(listener) {
    this.listeners.push(listener);
    return () => {
      this.listeners.splice(this.listeners.indexOf(listener), 1);
    };
  }

  initFromStorage() {
    try {
      const savedUser = this.storage.getItem();

      if (savedUser) {
        const { user, token, sessionId } = savedUser;
        if (user && token) {
          this.setUser(user, token, sessionId);
          this.init();
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  async logout() {
    // eslint-disable-next-line no-undef
    this.storage.removeItem();
    this.userId = null;
    this.eventId = null;
    this.token = null;
    this.dispatch();
  }
}

const store = new Store();
function connect(ComponentToWrap) {
  class StoreComponent extends Component {
    constructor(props, context) {
      super(props, context);

      this.unsubscribe = store.subscribe((state) => this.setState(state));

      this.state = store.getState();
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    render() {
      if (!store.isInit) {
        return null;
      }
      return <ComponentToWrap {...this.state} {...this.props} />;
    }
  }

  // on retourne notre wrapper
  return StoreComponent;
}

store.connect = connect;
export default store;
