import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from 'semantic-ui-react';

import './Home.scss';
import Tiles from './Tiles';

import BlockContainer from '../components/BlockContainer';
import Blocks from '../components/Blocks';
import BrowserCheckBlock from '../components/cms/BrowserCheckBlock';
import CTATileBlock from '../components/cms/CTATileBlock';
import CarouselBlock from '../components/cms/CarouselBlock';
import HtmlBlock from '../components/cms/HtmlBlock';
import IframeBlock from '../components/cms/IframeBlock';
import LogosBlock from '../components/cms/LogosBlock/LogosBlock';
import VideosBlock from '../components/cms/VideosBlock';

import { useScreenConfig } from '../config/screens.context';

import HeaderBlocks from '../headers/HeaderBlocks';
import Banner from '../headers/blocks/Banner';

import BannerAds from './blocks/BannerAds';
import BannerAndUpcoming from './blocks/BannerAndUpcoming';
import BookingBlock from './blocks/BookingBlock';
import CloudTvBlock from './blocks/CloudTvBlock/CloudTvBlock';
import CountdownBlock from './blocks/CountdownBlock';
import FlocklerBlock from './blocks/FlocklerBlock';
import ImageWithIcons from './blocks/ImageWithIcons';
import ImageWithZones from './blocks/ImageWithZones';
import LiveQuestions from './blocks/LiveQuestions';
import LiveSessionsBlock from './blocks/LiveSessionsBlock';
import SVGMap from './blocks/SVGMap';
import SocialWallWidgetBlock from './blocks/SocialWallWidgetBlock';
import UpcomingWorkshopsBlock from './blocks/UpcomingWorkshopsBlock';
import VideoBlock from './blocks/VideoBlock';
import WorkshopsBlock from './blocks/WorkshopsBlock';
import WorkshopsWithRegistrationBlock from './blocks/WorkshopsWithRegistrationBlock';

import flocklerUtils from './components/Flockler/flocklerUtils';

// eslint-disable-next-line @typescript-eslint/no-use-before-define
const BlockRow = ({ _id, columns, blocks, className, ...props }) => (
  <Grid.Row key={_id} className={className} stretched>
    {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
    <Tiles {...props} blocks={blocks || columns} blockComponents={blockComponents} />
  </Grid.Row>
);

const BlockColumn = ({ _id, blocks, className, ...props }) => (
  <Grid className={className}>
    {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
    <Blocks {...props} blocks={blocks} blockComponents={blockComponents} />
  </Grid>
);

const HomeRow = ({ children, className }) => (
  <Grid.Row stretched className={className}>
    <Grid.Column width={16}>{children}</Grid.Column>
  </Grid.Row>
);

const BlockWrapper = ({ className, containerType, header, blocks, ...props }) => (
  <BlockContainer containerType={containerType} header={header}>
    <Grid className={className}>
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <Blocks {...props} blocks={blocks} blockComponents={blockComponents} />
    </Grid>
  </BlockContainer>
);

HomeRow.propTypes = {
  children: PropTypes.node.isRequired,
};

function wrapWithHomeRow(Component, type) {
  return (props) => (
    <HomeRow className={`home-block--${type}`}>
      <Component {...props} />
    </HomeRow>
  );
}

const blockComponents = {
  banner: wrapWithHomeRow(Banner, 'banner'),
  bannerAds: wrapWithHomeRow(BannerAds, 'bannerAds'),
  bannerAndUpcoming: BannerAndUpcoming,
  blockContainer: wrapWithHomeRow(BlockWrapper, 'blockContainer'),
  bookingBlock: wrapWithHomeRow(BookingBlock, 'booking'),
  browserCheck: wrapWithHomeRow(BrowserCheckBlock, 'browserCheck'),
  carousel: wrapWithHomeRow(CarouselBlock, 'carousel'),
  cloudTv: wrapWithHomeRow(CloudTvBlock, 'video'),
  column: BlockColumn,
  countdown: wrapWithHomeRow(CountdownBlock, 'countdown'),
  ctaTile: CTATileBlock,
  flockler: FlocklerBlock,
  html: wrapWithHomeRow(HtmlBlock, 'html'),
  iframe: wrapWithHomeRow(IframeBlock, 'iframe'),
  imageWithIcons: wrapWithHomeRow(ImageWithIcons, 'imageWithIcons'),
  imageWithZones: wrapWithHomeRow(ImageWithZones, 'imageWithZones'),
  liveSessions: LiveSessionsBlock,
  logos: wrapWithHomeRow(LogosBlock, 'logos'),
  row: BlockRow,
  // TODO: replace with normal social wall ? Breaks already deployed projects...
  socialWall: wrapWithHomeRow(SocialWallWidgetBlock, 'socialWall'),
  socialWallWidget: wrapWithHomeRow(SocialWallWidgetBlock, 'socialWallWidget'),
  svgMap: wrapWithHomeRow(SVGMap, 'svgMap'),
  liveQuestions: wrapWithHomeRow(LiveQuestions, 'live-questions'),
  upcomingWorkshops: UpcomingWorkshopsBlock,
  video: VideoBlock,
  videos: wrapWithHomeRow(VideosBlock, 'videos'),
  workshops: WorkshopsBlock,
  workshopsWithRegistration: wrapWithHomeRow(WorkshopsWithRegistrationBlock),
};

const Home = () => {
  const { banner, video, blocks, headerBlocks = [] } = useScreenConfig('home');

  if (blocks) {
    return (
      <Grid className="Home">
        <HeaderBlocks blocks={headerBlocks} wrapperComponent={HomeRow} />
        <Blocks blockComponents={blockComponents} blocks={blocks} />
      </Grid>
    );
  }

  const flocklerConfig = flocklerUtils.getConfig();
  return (
    <Grid>
      <HeaderBlocks blocks={headerBlocks} wrapperComponent={HomeRow} />
      <BannerAndUpcoming banner={banner} />
      {video && <VideoBlock {...video} />}
      {flocklerConfig && <FlocklerBlock config={flocklerConfig} />}
    </Grid>
  );
};

export default Home;
